import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AboutComponent} from './pages/about/about.component';
import {CvComponent} from './pages/cv/cv.component';
import {PortfolioComponent} from './pages/portfolio/portfolio.component';
import {ContactComponent} from './pages/contact/contact.component';
import {ProjectComponent} from './pages/project/project.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: AboutComponent
  },
  {
    path: 'cv',
    component: CvComponent
  },
  {
    path: 'portfolio',
    component: PortfolioComponent
  },
  {
    path: 'portfolio/project/:id',
    component: ProjectComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
