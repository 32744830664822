import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'js-about.page',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  public images = {
    me: '/assets/images/me-m.jpg',
    leadership: '/assets/images/leadership-m.jpg',
    architecture: '/assets/images/architecture-m.jpg',
    development: '/assets/images/development-m.jpg',
    communication: '/assets/images/communication-m.jpg'
  };
  public today = new Date();
  public leadershipYrs = this.today.getFullYear() - 2011;
  public devYrs = this.today.getFullYear() - 2004;

  public me = {
    properties: [
      {key: 'By Day', value: 'Tech Lead @ Railroad19, Inc'},
      {key: 'By Night', value: 'Full-time superhero to my better half and six kids!'},
      {key: 'Location', value: 'Norton Shores, MI, USA'},
      {key: 'Profession', value: 'Software Development'},
      {key: 'Degree', value: 'B.S. Computer Science'},
      {key: 'Freelance', value: 'Available'}
    ]
  };

  constructor() { }

  ngOnInit() {
  }

}
