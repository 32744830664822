import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private projects = {
    'bby-agent-ui': {
      title: 'Geek Squad Agent UI App',
      period: '2014 - 2015',
      description: 'Employee-facing appointment scheduling application. Developed as a responsive web app using AngularJS, Bootstrap, and ASP.NET MVC',
      longDescription: '<p>Best Buy needed to re-write an appointment scheduling application from the ground-up to give it a better look and feel, and also to be able to maintain the application better for the future. Additionally, they wanted to allow the application to be branded differently depending upon the user logged in to the system.</p><p>I led a team of 8 including a UX Designer, 2 QA analysts, and 5 developers to create a responsive web application with a modern design. The application actually consisted of 3 separate UIs but utilized the same codebase. We used AngularJS to manage the transitions in state, HTML5 session storage to cache queries made to the API, and utilized Angular Virtual DOM technology to speed up rendering of the few different calendar widgets that were part of the application. Additionally, I helped architect the back-end layer to be a pass-through to a large SOAP-based service. The back-end would allow any service/method to be called from the front-end and simply converted the JSON to XML and vice-versa so that the front-end layer could easily communicate with the SOAP-based web service.</p></p>',
      skills: [
        'ASP.NET MVC',
        'AngularJS',
        'Bootstrap',
        'HTML5',
        'CSS3',
        'JavaScript',
        'Angular Virtual DOM',
        'Grunt',
        'SASS',
        'Bower',
        'NPM'
      ],
      image: '/assets/portfolio/BestBuy/GeekSquad/AgentUI/Autotech3.png',
      screenshots: [
        '/assets/portfolio/BestBuy/GeekSquad/AgentUI/Admin1-min.png',
        '/assets/portfolio/BestBuy/GeekSquad/AgentUI/Admin2-min.png',
        '/assets/portfolio/BestBuy/GeekSquad/AgentUI/Admin3-min.png',
        '/assets/portfolio/BestBuy/GeekSquad/AgentUI/Admin4-min.png',
        '/assets/portfolio/BestBuy/GeekSquad/AgentUI/Admin5-min.png',
        '/assets/portfolio/BestBuy/GeekSquad/AgentUI/Admin6-min.png',
        '/assets/portfolio/BestBuy/GeekSquad/AgentUI/Autotech1-min.png',
        '/assets/portfolio/BestBuy/GeekSquad/AgentUI/Autotech2-min.png',
        '/assets/portfolio/BestBuy/GeekSquad/AgentUI/Autotech3.png',
        '/assets/portfolio/BestBuy/GeekSquad/AgentUI/Autotech4-min.png',
        '/assets/portfolio/BestBuy/GeekSquad/AgentUI/Precinct1-min.png',
        '/assets/portfolio/BestBuy/GeekSquad/AgentUI/Precinct2-min.png',
        '/assets/portfolio/BestBuy/GeekSquad/AgentUI/Precinct3-min.png'
      ]
    },
    'nbcu-move-command': {
      title: 'NBCUniversal MoveCommand App',
      period: '2014-2016',
      description: 'App developed to help facilitate moving people between building locations. Developed as a responsive web MEAN stack application (MySql, Express, AngularJS, and Node.js).',
      longDescription: '<p>Architected and developed a responsive MEAN stack application for coordinating office "moves" within NBCUniversal. This application was a re-write of an existing app. NBCUniversal wanted to create a responsive web interface accessible from any device and still be user-friendly across all form factors. I lead the team of 4 others including a UX Designer, 2 developers, and a QA Automation Engineer. This application also integrated with NBCUniversal\'s SSO for user authentication. In addition to the development of the application, I also architected and developed the base framework for an Automation Suite using Node.js and Mocha.js. Lastly, I worked with the NBCUniversal DevOps team to setup Continuous Integration through IBM\'s uBuild and uDeploy technology.</p>',
      image: '/assets/portfolio/NBCUniversal/MoveCommand/MC1.png',
      skills: [
        'Node.js', 'Express', 'MySQL', 'Node ORM 2', 'SAML2-JS for SSO', 'AngularJS', 'CSS3', 'HTML5', 'Bootstrap', 'Google Charts', 'Grunt', 'SASS', 'Bower', 'NPM'
      ],
      screenshots: [
        '/assets/portfolio/NBCUniversal/MoveCommand/MC1.png',
        '/assets/portfolio/NBCUniversal/MoveCommand/MC2-min.png',
        '/assets/portfolio/NBCUniversal/MoveCommand/MC3-min.png',
        '/assets/portfolio/NBCUniversal/MoveCommand/MC4-min.png',
        '/assets/portfolio/NBCUniversal/MoveCommand/MC5-min.png',
        '/assets/portfolio/NBCUniversal/MoveCommand/MC6-min.png',
        '/assets/portfolio/NBCUniversal/MoveCommand/MC7-min.png'
      ]
    },
    'bby-geek-squad-app': {
      title: 'Geek Squad Mobile App',
      period: '2014-2015',
      link: 'http://invis.io/ZG1VGAVSE',
      description: 'Consumer-facing iOS/Android app to assist customers in transferring data, making appointments, and diagnosing device problems.',
      longDescription: '<p>Best Buy wanted to redesign the Geek Squad Mobile application to take advantage of the latest mobile technologies for both iOS and Android while also introducing new features to the application. I worked as the Technical Lead across two developments teams (one for iOS and one for Android), and I contributed to the Android mobile application code. I created an architecture and technical design which included building a robust framework on both iOS and Android. The framework architecture included building different components that could easily be plugged into the mobile application dynamically to add/remove app features easily. While leading and managing the two different teams development in parallel, I contributed to building a component for transferring contacts, photos, videos and messages between devices connected to a Local Area Network. The solution for this component used JSON and 256-bit encryption against SSL Sockets.</p><p>While working on this project I was slated to build some different POCs (Proof of Concepts). One was a speed test application to determine network bandwidth and internet connection speed. Another was for device management including abilities to remotely take a picture, get the device\'s location, and immediately lock the screen with a passcode.</p><p>In addition to leading the technical design and architecture of the application and the development of both teams, I also lead the UX design and contributed in creating prototypes for the wire-frames and designs that were created.</p>',
      image: '/assets/portfolio/BestBuy/GeekSquad/GSA/GSA1.png',
      skills: [
        'Android SDK', 'Android Studio', 'Google Maps SDK', 'iOS SDK', 'FluidUI'
      ],
      screenshots: [
        '/assets/portfolio/BestBuy/GeekSquad/GSA/GSA1.png',
        '/assets/portfolio/BestBuy/GeekSquad/GSA/GSA2-min.png',
        '/assets/portfolio/BestBuy/GeekSquad/GSA/GSA3-min.png',
        '/assets/portfolio/BestBuy/GeekSquad/GSA/GSA5-min.png',
        '/assets/portfolio/BestBuy/GeekSquad/GSA/GSA6-min.png',
        '/assets/portfolio/BestBuy/GeekSquad/GSA/GSA7-min.png',
        '/assets/portfolio/BestBuy/GeekSquad/GSA/GSA8-min.png',
        '/assets/portfolio/BestBuy/GeekSquad/GSA/GSA9-min.png',
        '/assets/portfolio/BestBuy/GeekSquad/GSA/GSA10-min.png'
      ]
    },
    'bby-fms': {
      title: 'FMS Mobile App',
      period: '2014-2016',
      description: 'Native Android mobile app to assist in customer order handling for agents in the fields.',
      longDescription: '<p>Designed and developed a native Android mobile application which integrated with REST-based web services and Google Maps to allow Geek Squad agents to effectively manage client orders in the field. Worked with the business team to define requirements and guidelines; created wire-frames and a functional prototype which the application was built from.</p><p>This was my very first UX project too. I had never really done enterprise-level UX designs before and it kind of just fell into my lap. I used FluidUI to do the full design of the application which also allowed prototype functionality so business users could look through and approve designs and workflow.</p>',
      image: '/assets/portfolio/BestBuy/GeekSquad/FMS/FMS3.png',
      skills: [
        'Android SDK', 'Google Maps SDK', 'Android Studio', 'FluidUI'
      ],
      screenshots: [
        '/assets/portfolio/BestBuy/GeekSquad/FMS/FMS1.png',
        '/assets/portfolio/BestBuy/GeekSquad/FMS/FMS2-min.png',
        '/assets/portfolio/BestBuy/GeekSquad/FMS/FMS3.png',
        '/assets/portfolio/BestBuy/GeekSquad/FMS/FMS4-min.png',
        '/assets/portfolio/BestBuy/GeekSquad/FMS/FMS5-min.png',
        '/assets/portfolio/BestBuy/GeekSquad/FMS/FMS6-min.png',
        '/assets/portfolio/BestBuy/GeekSquad/FMS/FMS7-min.png',
        '/assets/portfolio/BestBuy/GeekSquad/FMS/FMS8-min.png',
        '/assets/portfolio/BestBuy/GeekSquad/FMS/FMS9-min.png',
      ]
    },
    'bby-ecommerce-prototype': {
      title: 'BestBuy.com eCommerce Prototype',
      period: 'April 2012',
      description: 'eCommerce Prototype built for a usability study using pure HTML5, JavaScript and CSS.',
      longDescription: '<p>This was a configurable prototype built for a Usability Study with Best Buy. I lead a team of 4 (including myself) to develop this with tight deadlines. We achieved all desired functionality within a month\'s time. The team at Best Buy was very impressed with our progress stating "you saved our Usability Study!" The reason they said that was because another team was supposed to do the prototype, but couldn\'t meet the deadlines and their quality was very poor. When my team and I stepped in, Best Buy couldn\'t believe how fast we were coding and with high quality!</p><p>In this implementation, we used HTML5 LocalStorage for saving states. This was also a very configurable solution which allowed the Best Buy Usability Team to create different versions of the prototype to test out on users for better feedback.</p>',
      image: '/assets/portfolio/BestBuy/DOTCOMUI/dotcom1.png',
      skills: [
        'CSS', 'HTML5', 'JavaScript', 'jQuery'
      ],
      screenshots: [
        '/assets/portfolio/BestBuy/DOTCOMUI/dotcom1.png',
        '/assets/portfolio/BestBuy/DOTCOMUI/dotcom2-min.png',
        '/assets/portfolio/BestBuy/DOTCOMUI/dotcom3-min.png',
        '/assets/portfolio/BestBuy/DOTCOMUI/dotcom4-min.png',
        '/assets/portfolio/BestBuy/DOTCOMUI/dotcom5-min.png',
        '/assets/portfolio/BestBuy/DOTCOMUI/dotcom6-min.png',
        '/assets/portfolio/BestBuy/DOTCOMUI/dotcom7-min.png',
        '/assets/portfolio/BestBuy/DOTCOMUI/dotcom8-min.png',
        '/assets/portfolio/BestBuy/DOTCOMUI/dotcom9-min.png',
        '/assets/portfolio/BestBuy/DOTCOMUI/dotcom10-min.png',
      ]
    },
    'dstiles-construction-inc': {
      title: 'D. Stiles Construction, Inc.',
      period: 'August 2014',
      description: 'Construction services company page built using single page design with HTML5, CSS, and JavaScript.',
      longDescription: '<p>This is actually my Dad\'s website who owns a construction business. He needed a simple, one-page website that emphasizes the experience and services his company provides. Another important factor was enabling potential customers with a quick and easy way to contact him.</p><p>Outside of the images, social media icons, and google maps; the look and feel was accomplished with straight HTML5 and CSS. The image carousel at the top was achieved with ThemePunch\'s Revolution slider plug-in. The backend was coded in PHP which also handles the contact form submission.</p>',
      link: 'http://www.stilesconstructioninc.com',
      image: '/assets/portfolio/DStilesConstructionInc/dstilesconstruction1.png',
      screenshots: [
        '/assets/portfolio/DStilesConstructionInc/dstilesconstruction1.png',
      ],
      skills: [
        'PHP', 'HTML5', 'CSS3', 'JavaScript'
      ]
    },
    'iridescent-moments': {
      title: 'Iridescent Moments Photography',
      period: 'Fall 2013',
      link: 'http://www.iridescentmoments.com',
      description: 'Photography portfolio site using minimalist design and built with CodeIgniter/PHP, HTML5, CSS and JavaScript.',
      longDescription: '<p>This is a photography portfolio website using a clean, minimalist design. The objectives of this website was to showcase the photographer\'s portfolio and services.</p><p>The website was coding using the CodeIgnither PHP framework. The front-end uses HTML5, CSS, JavaScript, jQuery, and the jQuery ColorBox Plug-In.</p>',
      image: '/assets/portfolio/IridescentMoments/iridescentmoments-home.png',
      screenshots: [
        '/assets/portfolio/IridescentMoments/iridescentmoments-home.png',
        '/assets/portfolio/IridescentMoments/contact.png',
        '/assets/portfolio/IridescentMoments/contact.png',
        '/assets/portfolio/IridescentMoments/portfolio.png',
        '/assets/portfolio/IridescentMoments/pricing.png',
        '/assets/portfolio/IridescentMoments/about.png',
      ],
      skills: [
        'PHP', 'HTML5', 'CSS3', 'JavaScript'
      ]
    },
    'bby-scheduling': {
      title: 'Best Buy: Scheduling App',
      period: '2012',
      link: 'https://scheduling.bestbuymobile.com',
      description: 'Responsive appointment scheduling application built using CodeIgniter/PHP, HTML, CSS and JavaScript.',
      longDescription: '<p>During my time at Systems In Motion, I was involved in several projects. One of these projects was to develop a customer-facing appointment scheduling application. This app enables customers to schedule appointments to pick up their product at the nearest Best Buy store. In addition to being customer-facing, it also had an Administrative Control Panel that enabled Store Managers and Store Employees to configure stores and products in the system, along with fulfilling appointments at the store.</p><p>I held the Lead Developer position for the duration of the first phase of this project before moving into management. In the second phase of this project, the business wanted to make the site work better on mobile phones so I created a responsive solution which automatically detected the screen size of the client machine/device, and updated the styling automatically.</p>',
      image: '/assets/portfolio/BestBuy/Scheduling/bby-scheduling-home.png',
      screenshots: [
        '/assets/portfolio/BestBuy/Scheduling/bby-scheduling-home.png',
        '/assets/portfolio/BestBuy/Scheduling/bby-scheduling-mobile.png',
        '/assets/portfolio/BestBuy/Scheduling/bby-scheduling-select-appt.png',
      ],
      skills: [
        'HTML', 'CSS', 'JavaScript', 'jQuery', 'CodeIgniter', 'PHP', 'Smarty'
      ]
    },
    'bby-tech-support': {
      title: 'Best Buy: Tech Support',
      period: '2013-2014',
      description: 'eCommerce site for purchasing Best Buy Tech Support related services. Built using Java, Spring and Websphere.',
      longDescription: '<p>During my time at Systems In Motion, I was involved in several projects. One of these projects was to create a website that allowed customers to purchase Tech Support and Anti-Virus subscriptions. Additionally, the website also needed to work inside of an iFrame that GeekSquad agents use when chatting with customers over the web.</p><p>The course of this project spanned a few years where I held the Development Manager position and oversaw the entire project delivery. I managed a team of developers and QA analysts, and followed the Agile methodology to deliver specific functionalities each sprint. I was also the point-of-contact from a technical perspective for the busineses team and answered questions as needed.</p>',
      image: '/assets/portfolio/BestBuy/TechSupport/tech-support-digital-sales.png',
      screenshots: [
        '/assets/portfolio/BestBuy/TechSupport/tech-support-bp-sales.png',
        '/assets/portfolio/BestBuy/TechSupport/tech-support-digital-sales.png',
        '/assets/portfolio/BestBuy/TechSupport/tech-support-ss-flow.png',
      ],
      skills: [
        'Java', 'Spring', 'Websphere', 'HTML', 'CSS', 'JavaScript', 'jQuery'
      ]
    },
    'potestivo-palms': {
      title: 'Potestivo &amp; Associates: PALMS',
      period: '2007 - 2011',
      description: 'Custom intranet and legal management system with several tools. Developed with PHP, HTML, CSS and JavaScript.',
      longDescription: '<p>During my time at Potestivo & Associates, I was involved in several projects. One of these projects was to create a sophisticated and custom Intranet for the company that would later develop into holding several different tools, and even an entire case management system.</p><p>Starting out, PALMS was just a simple Intranet application where employees could log on, read about the company\'s latest news and important announcements, and get access to company documents and useful web links. It eventually evolved into an entire suite of applications all in one place.</p><p>Not only was PALMS a complete and custom Content Management System, but it also contained a plethora of tools used by the company such as: a complete time management system, a task manager, event calendar, employee management system, reporting system, case management system, company classifieds, and so much more.</p><p>PALMS\' case management system was designed to be fast and efficient without being too complicated to use. This allowed workers to spend more time working the case file and less time fumbling around the interface. In addition, PALMS made it easy to stay on task through use of a checklist system. Each case file has its own checklist (i.e. a listing of steps that must be taken from when the case file is open until it is closed). Each step could either be manually completed by the case worker, or automatically by the system. That\'s right, PALMS had several automation features. Based on a set of conditions or scenarios, PALMS could automatically create tasks for case workers, schedule events, send out important emails or reminders, and even assemble important legal documents relevant to the case file.</p>',
      image: '/assets/portfolio/Potestivo/palms/Home.png',
      skills: [
        'PHP', 'MySQL', 'Apache', 'HTML', 'CSS', 'JavaScript', 'jQuery'
      ],
      screenshots: [
        '/assets/portfolio/Potestivo/palms/Calendar.png',
        '/assets/portfolio/Potestivo/palms/EmployeeManager.png',
        '/assets/portfolio/Potestivo/palms/Home.png',
        '/assets/portfolio/Potestivo/palms/Login.png',
        '/assets/portfolio/Potestivo/palms/MeetingAgendaSystem.png',
      ]
    },
    'potestivo-site': {
      title: 'Potestivo &amp; Associates: Website',
      period: '2010',
      description: 'App developed to help facilitate moving people between building locations. Developed as a responsive web MEAN stack application (MySql, Express, AngularJS, and Node.js).',
      longDescription: '<p>During my time at Potestivo & Associates, I was involved in several projects. One of these projects was to re-design the company\'s website, and make it easy for the marketing staff to update it regularly.</p><p>The new website included a custom content management system (similar to PALMS) which made it super easy to add new blog posts and articles to the site. It also mame it easy to add and update attorney and staff biography pages. The website was also optimized for Search Engines, and included Google Analytics for tracking visitors.</p>',
      image: '/assets/portfolio/Potestivo/site/potestivolaw-home.gif',
      skills: [
        'PHP', 'HTML', 'CSS', 'JavaScript', 'jQuery'
      ],
      screenshots: [
        '/assets/portfolio/Potestivo/site/AdminCP.png',
        '/assets/portfolio/Potestivo/site/LatestNews.png',
        '/assets/portfolio/Potestivo/site/OurPeople.png',
        '/assets/portfolio/Potestivo/site/potestivolaw-home.png',
      ]
    }
  };

  constructor() {
  }

  public getProjectById(id) {
    return this.projects[id];
  }
}
