import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'js-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public form = {
    challenge: null,
    name: '',
    from: '',
    subject: '',
    phone: '',
    message: ''
  };
  public me = '/assets/images/me-nathan.jpg';
  public result = '';

  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  public submit() {
    if (!this.form.challenge || this.form.challenge !== '5') {
      this.result = 'You failed the challenge! Try using your fingers to find the solution.';
      return;
    }

    this.http.post('/api/contact', this.form).subscribe(() => {
      this.result = 'Message sent, thank you!';
    }, () => {
      this.result = 'An error occurred and this form could not be submitted at this time. Please try again later.';
    });
  }

}
