import {NgModule} from '@angular/core';
import {VerticalNavComponent} from './components/vertical-nav/vertical-nav.component';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { SocialLinksComponent } from './components/social-links/social-links.component';
import {RouterModule} from '@angular/router';
import {ProjectService} from './services/project.service';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    FontAwesomeModule
  ],
  declarations: [
    VerticalNavComponent,
    SocialLinksComponent
  ],
  providers: [
    ProjectService
  ],
  exports: [
    SocialLinksComponent,
    VerticalNavComponent
  ]
})
export class SharedModule { }
