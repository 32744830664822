import {Component, OnInit} from '@angular/core';
import {CV} from '../../types/cv';
import {faPlusSquare, faMinusSquare, faDownload} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'js-cv',
  templateUrl: './cv.component.html',
  styleUrls: ['./cv.component.scss']
})
export class CvComponent implements OnInit {

  public downloadIcon = faDownload;
  public minusIcon = faMinusSquare;
  public plusIcon = faPlusSquare;
  public today = new Date();
  public totalYears = this.today.getFullYear() - 2004;
  public cv: CV = {
    jobs: [
      {
        title: 'Tech Lead, Front-End Development',
        company: 'Railroad19, Inc',
        period: 'February 2019 - Present',
        feats: [
          'Leading a small team on front-end development for a small suite of applications',
          'Developing in the latest versions of Angular, NodeJS, and RxJS'
        ]
      },
      {
        title: 'Sr. Front-End Developer',
        company: 'Railroad19, Inc',
        period: 'January 2018 - February 2019',
        feats: [
          'Developed web-based applications for the NBCUniversal Ad Sales team using the latest versions of Angular',
          'Created several interactive UX designs using UxPin that have received very positive feedback on speed and quality',
          'Worked remotely with small teams of 1-3 senior developers',
          'Angular 5-7, RxJS, Bootstrap, SASS'
        ]
      },
      {
        title: 'Web Development Manager',
        company: 'Integrity Media Group',
        period: 'June 2016 - Present',
        feats: [
          'Hired in as a Senior PHP Developer and promoted to Web Development Manager after 7 months; working for one of the largest RV dealers in the nation.',
          'Worked with/managed a team of developers: 3-5 on-site, 3-5 remote, and closely worked with offshore teams.',
          'Re-architected their multi-site system (managed with Wordpress) to a more sophisiticated client/server architecture utilizing Laravel and AngularJS. Laravel for the API, AngularJS used as a Single Page App (SPA). Used Nginx for the web server and AWS for hosting.',
          'Built a pre-rendering service using Node.js and Headless Chrome. This could crawl pages, pre-render them, and serve them to bots/users. This decreased page responsive time by at least 80%. Integrated with Laravel to serve pre-rendered pages and allow SPA to take over after that.'
        ]
      },
      {
        title: 'Senior Manager, Delivery',
        company: 'Nexient',
        period: 'January 2015 - June 2016',
        feats: [
          'Promoted to Senior Delivery Manager in January 2015; supervising 5-7 developers, 1-3 QA Analysts, and 1-2 UX designers.',
          'Worked with Best Buy to re-write an existing Scheduling system from scratch using ASP.NET MVC, AngularJS, and Bootstrap. Solution integrated with a third-party system (Time Trade).',
          'Worked with NBCUniversal to architect and design a new MEAN-stack application to assist them in their IT needs.',
          'Worked to streamline processes and develop efficient solutions for teams.',
          'Functioned as the Platform Technical Lead and Technical POC for the Best Buy Geek Squad Mobile Application.',
          'Designed and architected the Geek Squad Mobile application, and its components, across the iOS and Android platforms.',
          'Produced thorough technical documentation including technical design, sequence and swimlane diagrams, and architecture.'
        ]
      },
      {
        title: 'Manager, Delivery',
        company: 'Nexient',
        period: 'June 2013 - January 2015',
        feats: [
          'Directly overseeing and managing the development activities on multiple projects and teams with different languages and technologies; utilized the agile development methodology.',
          'Directly working with high-profile clients providing expert consulting in both application architecture and development.',
          'Led UX team in designing mobile applications, websites, and dashboards.',
          'Leading UX team in designing applications across platforms and form factors.',
          'Designed and developed a native Android Mobile Phone application for Geek Squad to assist agents in managing client orders in the field. The application integrated with REST-based web services and Google Maps.',
          'Developed an internal tool for project management and tracking utilizing HTML5, CSS and AngularJS.',
          'Delegated tasks, scheduled and balanced team activities to meet project deadlines.',
          'Mentored developers in best practices and skill development, and provided periodic reviews.'
        ]
      },
      {
        title: 'Development Manager',
        company: 'Nexient',
        period: 'February 2012 - June 2013',
        feats: [
          'Managed the development of several projects in both PHP and Java using the agile development methodology.',
          'Held daily stand ups, retrospectives, and technical design meetings with a few different teams consisting of 3-4 developers and QA analysts.',
          'Delivered high-quality projects on-time and with a low-rate of defects.',
          'Worked to streamline processes, coding standards and best practices within the organization.',
          'Led and developed a complete HTML5, CSS3 and JavaScript e-commerce solution on a prototype for a Usability Study.',
          'Produced detailed estimates for new projects.',
          'Worked to streamline processes within teams.',
          'Developed an automation script in VBScript using QTP for fully automating a few websites and calls to web services.',
          'Produced thorough technical documentation including technical design, sequence and swimlane diagrams, and architecture.',
          'Led Lunch n\' Learn sessions around Site Optimization, SEO, CAPTCHA and Chrome Developer Tools.'
        ]
      },
      {
        title: 'Lead Developer',
        company: 'Nexient',
        period: 'June 2011 - February 2012',
        feats: [
          'Developed a responsive web application using PHP, HTML5, CSS and JavaScript that allowed customers to schedule appointments through an intuitive and user-friendly interface.',
          'Recognized as a source of knowledge for others, specifically for skills in development such as CSS, HTML5, JavaScript, jQuery and PHP.',
          'Created test case generation tool which creates random XML docs from XML Schema.',
          'Developed and contributed code to other teams in the company for database-table object management, sprite generation, code deployment and configuration.',
          'Participated in daily stand ups and weekly team meetings; code reviews, and retrospectives.',
          'Led Lunch n\' Learn sessions on various coding topics.',
          'Managed activities of QA analyst on the team.'
        ]
      },
      {
        title: 'Programmer',
        company: 'Potestivo & Associates',
        period: 'July 2007 - June 2011',
        feats: [
          'Worked on building a sophisticated case management system to handle the company\'s legal records and documents.',
          'Created an Office plug-in in Outlook, Word and Excel to allow easy saving of emails and documents in the case management system.',
          'Designed and developed a unique Intranet that saved the company money and increased productivity of employees by streamlining several business processes.',
          'Developed several tools on the intranet for integrating with TimeMatters Case Management System.',
          'Created a flexible time management system complete with accruals, calendars, punch details, and a request system for approving overtime, vacation, time off, time clock corrections and timecards.',
          'Developed a user-friendly C# application to assist in opening new case files.',
          'Developed several web applications for handling legal matters, referrals, invoices, and documents.',
          'Assisted in solving many technical issues with servers, operating systems, applications and networks.',
          'Provided application integrations with third-party services using REST and XML.',
          'Maintained weekly action plans and JavaDoc-style code documentation.'
        ]
      },
      {
        title: 'GUI Developer',
        company: 'Gridalogy',
        period: 'Summer 2006 - Spring 2007',
        feats: [
          'Developed user-friendly GUIs for a grid-computing application in Java and Swing.',
          'Developed a unique GUI architecture and design plan.',
          'Attended weekly meetings, gave consistent progress reports and updated code through CVS.'
        ]
      },
      {
        title: 'Project Manager',
        company: 'Blue Marble Security Enterprise',
        period: 'October 2004 - July 2006',
        feats: [
          'Led a group of six students to develop a system that combined home security and portability.',
          'Held weekly meetings, kept team members on task, dealt with problems, and reported project deliverables.',
          'Maintained a budget, ordered materials, and met deadlines consistent with the goals of the project.',
          'Used Java to create a GUI application that interfaced with a piece of hardware for interaction with remote sensing devices.'
        ]
      },
      {
        title: 'Finance Chief',
        company: 'Blue Marble Security Enterprise',
        period: 'October 2004 - July 2006',
        feats: [
          'Developed a sophisticated GUI application in Java, maintained a budget, ordered materials, and met deadlines consistent with the goals of the project.'
        ]
      }
    ],
    projects: [
      {
        title: 'CSuite',
        period: '2018 - 2019',
        points: [
          'Designed and developed an industry-defining web application solution with a highly configurable grid system which allowed users to see, filter, drill down into, sort and manage data in many useful ways.',
          'Angular, Java / Spring'
        ]
      },
      {
        title: 'Angular Bootable States',
        period: '2016 - 2017',
        points: [
          'Developed a system in Node.js that can pre-render a page, serve it up with Laravel, and pre-boot the page with AngularJS (think of this as showing a fully rendered page, but allowing Angular to control any interaction after the fact). This has decreased page response times by at least 80% and allows bots to see all content of the site and crawl it for SEO purposes.',
          'AngularJS, Laravel, Node.js'
        ]
      },
      {
        title: 'Multi-Site Engine',
        period: '2016 - 2017',
        points: [
          'Worked on a complete rewrite of a multi-site Wordpress system and re-architected it with Laravel and AngularJS. The system currently serves 7 sites (with hundreds of landing pages) with different themes; and integrates with several third-party services including Facebook, Google, eBay, and more.',
          'AngularJS, Laravel, PHP, Node.js, CSS3, HTML5, JavaScript, SASS, REST, Gulp'
        ]
      },
      {
        title: 'Statement Builder',
        period: '2016 - 2017',
        points: [
          'Worked on a multi-site engine system powered by Statements (SQL statements stored as parsed objects in a database). Built the UI/parser that could quickly parse any SQL statement into objects which would allow you to edit and work with statements more easily.',
          'AngularJS, Laravel, PHP, Node.js, CSS3, HTML5, JavaScript, SASS, REST, Gulp'
        ]
      },
      {
        title: 'AgentUI Re-write/Magnolia',
        period: '2015 - 2016',
        points: [
          'Architected and developed a responsive web application for managing customer appointments for both Geek Squad and Magnolia as part of Best Buy.',
          'AngularJS, Bootstrap, CSS3, HTML5, JavaScript, Grunt, SASS, REST, ASP.NET MVC'
        ]
      },
      {
        title: 'MoveCommand',
        period: '2015',
        points: [
          'Architected and developed a responsive MEAN stack application for coordinating office "moves" within NBCUniversal.',
          'AngularJS, Bootstrap, CSS3, HTML5, JavaScript, Grunt, SASS, REST, MySQL, Express, Node.js'
        ]
      },
      {
        title: 'Geek Squad Mobile Application',
        period: '2014 - 2015',
        points: [
          'Architected a framework for the application across the iOS and Android platforms. Developed a component to allow remote device management with abilities to remotely take a picture, get the device’s location, and immediately lock the screen with a passcode. Developed a component for transferring contacts, photos, videos and messages between devices connected to a Local Area Network. Solution used JSON, and 256-bit encryption against SSL Sockets.',
          'Android, iOS'
        ]
      },
      {
        title: 'FMS Mobile Application',
        period: '2014 - 2016',
        points: [
          'Designed and developed a native Android mobile application which integrated with REST-based web services and Google Maps to allow Geek Squad agents to effectively manage client orders in the field. Worked with the business team to define requirements and guidelines; created wire-frames and a functional prototype which the application was built from.',
          'Android SDK, Android Stdio, Google Maps SDK'
        ]
      },
      {
        title: 'Project Management Web Application',
        period: '2013-2016',
        points: [
          'Developed a web application for managing agile software development projects and handling project status reports.',
          'HTML5, CSS2.1/3, JavaScript, AngularJS'
        ]
      },
      {
        title: 'eCommerce Prototype',
        period: '2012',
        points: [
          'Built a prototype for a usability study.',
          'HTML5, CSS3, JavaScript, jQuery'
        ]
      },
      {
        title: 'Appointment Scheduling System',
        period: '2011-2012',
        points: [
          'Led the development of a LAMP-stack solution for a responsive-web appointment scheduling system.',
          'PHP, MySQL, HTML5, CSS2.1, JavaScript, jQuery'
        ]
      }
    ],
    education: [
      {
        degree: 'B.S. Computer Science',
        name: 'Michigan Technological University',
        period: 'Fall 2003 - Spring 2007',
        points: [
          'Courses: C++/Java, Discrete/Data Structures, Computer Organization, Algorithms, Team Software Project, Computer Networks, Software Engineering, and many more.',
          'Special Programs: Blue Marble Security Enterprise'
        ]
      },
      {
        degree: 'Concentration in Computer Engineering',
        name: 'Kellogg Community College',
        period: 'Fall 2001 - Spring 2003',
        points: [
          'Courses: C++/Java, Micro/Macro Economics, Philosophy, Calculus, Chemistry, English, and more.'
        ]
      }
    ],
    skillGroups: [
      {
        title: 'Top Skills',
        years: this.today.getFullYear() - 2004,
        progress: 100,
        skills: [
          'Agile Scrum / Development Management',
          'Front-end Development',
          'Back-end Development',
          'Mobile Development',
          'Web Services: REST, SOAP, XML'
        ]
      },
      {
        title: 'Front-End Development',
        years: this.today.getFullYear() - 2004,
        progress: 100,
        skills: [
          'HTML / XHTML, HTML5',
          'CSS 2.1 / 3',
          'JavaScript'
        ]
      },
      {
        title: 'Front-End Frameworks',
        years: this.today.getFullYear() - 2011,
        progress: 100,
        skills: [
          'Angular, AngularJS, Vue.js, jQuery',
          'LoDash, iCanHaz.js, Mustache.js',
          'Angular Material, Twitter Bootstrap, CSS Grids',
          'Angular Cli, Gulp, Grunt, Bower, SASS, LESS, NPM'
        ]
      },
      {
        title: 'Back-End Development',
        years: this.today.getFullYear() - 2007,
        progress: 100,
        skills: [
          'Node.js / Express, NestJS',
          'PHP / Laravel, CodeIgniter',
          'Java / Swing',
          'Relational Databases / SQL, NoSQL'
        ]
      },
      {
        title: 'Mobile Development',
        years: this.today.getFullYear() - 2013,
        progress: 100,
        skills: [
          'Native Android SDK / Android Studio',
          'Mobile Web using Responsive Design'
        ]
      },
      {
        title: 'Methodologies',
        years: this.today.getFullYear() - 2004,
        progress: 100,
        skills: [
          'Agile SCRUM',
          'MVC / MVVM',
          'Object-Oriented Programming',
          'Pair Programming'
        ]
      },
      {
        title: 'Web Services',
        years: this.today.getFullYear() - 2006,
        progress: 100,
        skills: [
          'REST',
          'SOAP',
          'XML'
        ]
      }
    ]
  };

  constructor() {
  }

  ngOnInit() {
    this.cv.skillGroups.forEach(group => {
      group.progress = Math.ceil((group.years / this.totalYears) * 100).toFixed(0);
    });
  }

}
