import {Component, OnInit} from '@angular/core';
import {faFacebookF, faGithub, faLinkedin, faStackOverflow, faTwitter} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'js-social-links',
  templateUrl: './social-links.component.html',
  styleUrls: ['./social-links.component.scss']
})
export class SocialLinksComponent implements OnInit {

  public socialLinks = [
    {
      icon: faFacebookF,
      link: 'http://www.facebook.com/jtstiles'
    },
    {
      icon: faTwitter,
      link: 'http://www.twitter.com/neosyler'
    },
    {
      icon: faLinkedin,
      link: 'http://www.linkedin.com/in/jasonstiles'
    },
    {
      icon: faGithub,
      link: 'https://github.com/neosyler'
    },
    {
      icon: faStackOverflow,
      link: 'https://stackoverflow.com/cv/neosyler'
    }
  ];

  constructor() {
  }

  ngOnInit() {
  }

}
