import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ProjectService} from '../../shared/services/project.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'js-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {

  public project;

  constructor(private route: ActivatedRoute, private projectService: ProjectService, private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.project = this.projectService.getProjectById(params.id);
      this.project.image = this.sanitizer.bypassSecurityTrustStyle(`url(${this.project.image})`);
    });
  }

}
