import { Component, OnInit } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'js-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {

  public items = [
    {
      title: 'Geek Squad Agent UI App',
      description: 'Employee-facing appointment scheduling application. Developed as a responsive web app using AngularJS, Bootstrap, and ASP.NET MVC',
      image: '/assets/portfolio/BestBuy/GeekSquad/AgentUI/Autotech3.png',
      buttonList: {
        buttons: [
          {
            text: 'Read more',
            link: '/portfolio/project/bby-agent-ui',
            hrefOptions: {
              state: 'project',
              params: {id: 'bby-agent-ui'}
            },
            css: {
              classes: 'btn btn-primary'
            }
          }
        ]
      }
    },
    {
      title: 'NBCUniversal MoveCommand App',
      description: 'App developed to help facilitate moving people between building locations. Developed as a responsive web MEAN stack application (MySql, Express, AngularJS, and Node.js).',
      image: '/assets/portfolio/NBCUniversal/MoveCommand/MC1.png',
      buttonList: {
        buttons: [
          {
            text: 'Read more',
            link: '/portfolio/project/nbcu-move-command',
            hrefOptions: {
              state: 'project',
              params: {id: 'nbcu-move-command'}
            },
            css: {
              classes: 'btn btn-primary'
            }
          }
        ]
      }
    },
    {
      title: 'Geek Squad Mobile App',
      description: 'Consumer-facing iOS/Android app to assist customers in transferring data, making appointments, and diagnosing device problems.',
      image: '/assets/portfolio/BestBuy/GeekSquad/GSA/GSA1.png',
      buttonList: {
        buttons: [
          {
            text: 'Read more',
            link: '/portfolio/project/bby-geek-squad-app',
            hrefOptions: {
              state: 'project',
              params: {id: 'bby-geek-squad-app'}
            },
            css: {
              classes: 'btn btn-primary'
            }
          }
        ]
      }
    },
    {
      title: 'FMS Mobile App',
      description: 'Native Android mobile app to assist in customer order handling for agents in the fields.',
      image: '/assets/portfolio/BestBuy/GeekSquad/FMS/FMS3.png',
      buttonList: {
        buttons: [
          {
            text: 'Read more',
            link: '/portfolio/project/bby-fms',
            hrefOptions: {
              state: 'project',
              params: {id: 'bby-fms'}
            },
            css: {
              classes: 'btn btn-primary'
            }
          }
        ]
      }
    },
    {
      title: 'BestBuy.com eCommerce Prototype',
      description: 'eCommerce Prototype built for a usability study using pure HTML5, JavaScript and CSS.',
      image: '/assets/portfolio/BestBuy/DOTCOMUI/dotcom1.png',
      buttonList: {
        buttons: [
          {
            text: 'Read more',
            link: '/portfolio/project/bby-ecommerce-prototype',
            hrefOptions: {
              state: 'project',
              params: {id: 'bby-ecommerce-prototype'}
            },
            css: {
              classes: 'btn btn-primary'
            }
          }
        ]
      }
    },
    {
      title: 'D. Stiles Construction, Inc.',
      description: 'Construction services company page built using single page design with HTML5, CSS, and JavaScript.',
      image: '/assets/portfolio/DStilesConstructionInc/dstilesconstruction1.png',
      buttonList: {
        buttons: [
          {
            text: 'Read more',
            link: '/portfolio/project/dstiles-construction-inc',
            hrefOptions: {
              state: 'project',
              params: {id: 'dstiles-construction-inc'}
            },
            css: {
              classes: 'btn btn-primary'
            }
          },
          {
            text: 'Visit',
            link: 'http://stilesconstructioninc.com',
            target: '_blank',
            css: {
              classes: 'btn btn-primary'
            }
          }
        ]
      }
    },
    {
      title: 'Iridescent Moments Photography',
      description: 'Photography portfolio site using minimalist design and built with CodeIgniter/PHP, HTML5, CSS and JavaScript.',
      image: '/assets/portfolio/IridescentMoments/iridescentmoments-home.png',
      buttonList: {
        buttons: [
          {
            text: 'Read more',
            link: '/portfolio/project/iridescent-moments',
            hrefOptions: {
              state: 'project',
              params: {id: 'iridescent-moments'}
            },
            css: {
              classes: 'btn btn-primary'
            }
          },
          {
            text: 'Visit',
            link: 'http://iridescentmoments.com',
            target: '_blank',
            css: {
              classes: 'btn btn-primary'
            }
          }
        ]
      }
    },
    {
      title: 'Best Buy: Scheduling App',
      description: 'Responsive appointment scheduling application built using CodeIgniter/PHP, HTML, CSS and JavaScript.',
      image: '/assets/portfolio/BestBuy/Scheduling/bby-scheduling-home.png',
      buttonList: {
        buttons: [
          {
            text: 'Read more',
            link: '/portfolio/project/bby-scheduling',
            hrefOptions: {
              state: 'project',
              params: {id: 'bby-scheduling'}
            },
            css: {
              classes: 'btn btn-primary'
            }
          },
          {
            text: 'Visit',
            link: 'https://scheduling.bestbuymobile.com/',
            target: '_blank',
            css: {
              classes: 'btn btn-primary'
            }
          }
        ]
      }
    },
    {
      title: 'Best Buy: Tech Support',
      description: 'eCommerce site for purchasing Best Buy Tech Support related services. Built using Java, Spring and Websphere.',
      image: '/assets/portfolio/BestBuy/TechSupport/tech-support-digital-sales.png',
      buttonList: {
        buttons: [
          {
            text: 'Read more',
            link: '/portfolio/project/bby-tech-support',
            hrefOptions: {
              state: 'project',
              params: {id: 'bby-tech-support'}
            },
            css: {
              classes: 'btn btn-primary'
            }
          }
        ]
      }
    },
    {
      title: 'Potestivo &amp; Associates: PALMS',
      description: 'Custom intranet and legal management system with several tools. Developed with PHP, HTML, CSS and JavaScript.',
      image: '/assets/portfolio/Potestivo/palms/Home.png',
      buttonList: {
        buttons: [
          {
            text: 'Read more',
            link: '/portfolio/project/potestivo-palms',
            hrefOptions: {
              state: 'project',
              params: {id: 'potestivo-palms'}
            },
            css: {
              classes: 'btn btn-primary'
            }
          }
        ]
      }
    },
    {
      title: 'Potestivo &amp; Associates: Website',
      description: 'App developed to help facilitate moving people between building locations. Developed as a responsive web MEAN stack application (MySql, Express, AngularJS, and Node.js).',
      image: '/assets/portfolio/Potestivo/site/potestivolaw-home.gif',
      buttonList: {
        buttons: [
          {
            text: 'Read more',
            link: '/portfolio/project/potestivo-site',
            hrefOptions: {
              state: 'project',
              params: {id: 'potestivo-site'}
            },
            css: {
              classes: 'btn btn-primary'
            }
          }
        ]
      }
    }
  ];

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.items.forEach(item => {
      item.image = this.sanitizer.bypassSecurityTrustStyle(`url(${item.image}`) as any;
    });
  }

}
