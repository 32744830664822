import { Component, OnInit } from '@angular/core';
import {faBriefcase, faEnvelope, faFileAlt, faUserCircle} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'js-vertical-nav',
  templateUrl: './vertical-nav.component.html',
  styleUrls: ['./vertical-nav.component.scss']
})
export class VerticalNavComponent implements OnInit {

  public menu = {
    image: '/assets/images/me-t.jpg',
    name: 'Jason Stiles',
    items: [
      {
        text: 'About',
        icon: faUserCircle,
        link: '/'
      },
      {
        text: 'CV',
        icon: faFileAlt,
        link: '/cv'
      },
      {
        text: 'Portfolio',
        icon: faBriefcase,
        link: '/portfolio'
      },
      {
        text: 'Contact',
        icon: faEnvelope,
        link: '/contact'
      }
    ]
  };

  constructor() { }

  ngOnInit() {
  }

}
